import Cookies from 'universal-cookie';
import ls from 'local-storage'
// import { call } from 'redux-saga/effects';
const cookie = new Cookies();

export const getToken = () => {
    const user = getUser()
    if (user)
        return user.data
    return null
}

export const hasAccess = () => {
    return getUser() ? true : false
}

export const getUser = () => {
    const response = cookie.get('user')
    const resources = ls.get('resources');

    if (!response || !resources) return null 

    const user = {
        data: {
            ...response,
            resources
        }
    }

    return user
}

export const getRole = () => {
    const user = getUser()
    if (user)
        return user.role
    return null
}

export const saveUser = (response,callback) => {
    const { data : { resources }, data } = response;
    let newData = {...data}
    delete newData.resources
    cookie.set('user', newData, {path: '/'})
    ls.set('resources', resources);
    callback(true)
}

export const removeUser = (id) => {
    cookie.remove('user', {path: '/'})
    ls.remove('resources')
    console.log(`cookie set to null ${getUser()}`)
}
