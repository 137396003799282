export const MENU_APPLY_ITEMS = "MENU_APPLY_ITEMS";

export const ATTRIBUTE_SET_UPDATE_ITEMS = "ATTRIBUTE_SET_UPDATE_ITEMS";

export const CUSTOMER_GROUP_ADD_ITEMS = "CUSTOMER_GROUP_ADD_ITEMS";
export const CUSTOMER_GROUP_REMOVE = "CUSTOMER_GROUP_REMOVE";
export const UPDATE_CUSTOMER_GROUP_ITEM = "UPDATE_CUSTOMER_GROUP_ITEM";

// PRESERVE FILTER SALE ORDER
export const PRESERVE_SALE_ORDER_FILTER = "PRESERVE_SALE_ORDER_FILTER";
export const REMOVE_SALE_ORDER_FILTER = "REMOVE_SALE_ORDER_FILTER";

// PRESERVE FILTER SALE INVOICE
export const PRESERVE_SALE_INVOICE_FILTER = "PRESERVE_SALE_INVOICE_FILTER";
export const REMOVE_SALE_INVOICE_FILTER = "REMOVE_SALE_INVOICE_FILTER";

// PRESERVE FILTER SALE SHIPMENT
export const PRESERVE_SALE_SHIPMENT_FILTER = "PRESERVE_SALE_SHIPMENT_FILTER";
export const REMOVE_SALE_SHIPMENT_FILTER = "REMOVE_SALE_SHIPMENT_FILTER";

// PRESERVE FILTER SALE CREDIT MEMO
export const PRESERVE_SALE_MEMO_FILTER = "PRESERVE_SALE_MEMO_FILTER";
export const REMOVE_SALE_MEMO_FILTER = "REMOVE_SALE_MEMO_FILTER";

// PRESERVE FILTER BRAND
export const PRESERVE_BRAND_FILTER = "PRESERVE_BRAND_FILTER";
export const REMOVE_BRAND_FILTER = "REMOVE_BRAND_FILTER";

// PRESERVE FILTER BANNER
export const PRESERVE_BANNER_FILTER = "PRESERVE_BANNER_FILTER";
export const REMOVE_BANNER_FILTER = "REMOVE_BANNER_FILTER";
// PRESERVE FILTER CUSTOMER GROUP
export const PRESERVE_CUSTOMER_GROUP_FILTER = "PRESERVE_CUSTOMER_GROUP_FILTER";
export const REMOVE_CUSTOMER_GROUP_FILTER = "REMOVE_CUSTOMER_GROUP_FILTER";

// PRESERVE FILTER CUSTOMER
export const PRESERVE_CUSTOMER_FILTER = "PRESERVE_CUSTOMER_FILTER";
export const REMOVE_CUSTOMER_FILTER = "REMOVE_CUSTOMER_FILTER";

// PRESERVE FILTER TICKET
export const PRESERVE_TICKET_FILTER = "PRESERVE_TICKET_FILTER";
export const REMOVE_TICKET_FILTER = "REMOVE_TICKET_FILTER";

// PRESERVE FILTER PRODUCT
export const PRESERVE_PRODUCTFILTER = "PRESERVE_PRODUCTFILTER";
export const REMOVE_PRODUCTFILTER = "REMOVE_PRODUCTFILTER";

// PRESERVE FILTER PRODUCT ATTRIBUTE
export const PRESERVE_PRODUCT_ATTRIBUTE_FILTER = "PRESERVE_PRODUCT_ATTRIBUTE_FILTER";
export const REMOVE_PRODUCT_ATTRIBUTE_FILTER = "REMOVE_PRODUCT_ATTRIBUTE_FILTER";

// PRESERVE FILTER
export const PRESERVE_FILTER = "PRESERVE_FILTER";
export const REMOVE_FILTER = "REMOVE_FILTER";

// PRESERVE SHIPPPING AND TRACKING INFO
export const PRESERVE_SHIPPING_TRACKING_INFO = "PRESERVE_SHIPPING_TRACKING_INFO";
export const REMOVE_SHIPPING_TRACKING_INFO = "REMOVE_SHIPPING_TRACKING_INFO";

// for Create Product
export const INSERT_DATA_PRODUCT = "INSERT_DATA_PRODUCT";
export const REMOVE_DATA_PRODUCT = "REMOVE_DATA_PRODUCT";

// Call when purging all redux identities
export const PURGE_ALL = "PURGE_ALL";

export const USER_ACCESS = "USER_ACCESS";
export const PURGE_USER_ACCESS = "PURGE_USER_ACCESS";

// for Warehouse Reports
export const PRINT_WAREHOUSE_REPORTS = "PRINT_WAREHOUSE_REPORTS";
export const CLEAR_WAREHOUSE_REPORTS = "CLEAR_WAREHOUSE_REPORTS";
export const UPDATE_AGING_TYPE = "UPDATE_AGING_TYPE";
export const UPDATE_INVENTORY_STATE = "UPDATE_INVENTORY_STATE";
export const CLEAR_INVENTORY_STATE = "CLEAR_INVENTORY_STATE";
export const CHANGE_TOGGLE_TAB = "CHANGE_TOGGLE_TAB";

export const UserAccessAction = {
  saveUserAccess: saveUserAccess,
  purgeUserAccess: purgeUserAccess,
};

function saveUserAccess (data){
  return {
    type: USER_ACCESS,
    data,
  };
}

function purgeUserAccess (){
  return {
    type: PURGE_USER_ACCESS,
  };
}

export const PreserveProductFilterAction = {
  preserveProductFilter: preserveFilter,
  removeProductFilter: removeFilter,
};

function removeFilter (){
  return {
    type: REMOVE_PRODUCTFILTER,
  };
}

function preserveFilter (data){
  return {
    type: PRESERVE_PRODUCTFILTER,
    data,
  };
}

export const ProductAction = {
  saveDataProduct: insertProduct,
  removeDataProduct: removeProduct,
};

function insertProduct ({ value }){
  return {
    type: INSERT_DATA_PRODUCT,
    value,
  };
}

function removeProduct (){
  return {
    type: REMOVE_DATA_PRODUCT,
  };
}

export const MenuAction = {
  applyItems: applyItems,
};

function applyItems ({ menuItems, userType }){
  return {
    type: MENU_APPLY_ITEMS,
    menuItems,
    userType,
  };
}

function purgeAll (){
  return {
    type: PURGE_ALL,
  };
}

export const PurgeAction = {
  purge: purgeAll,
};

//////////////////////////
/// Product Attribute  ///
//////////////////////////
export const PreserveProductAttributeFilterAction = {
  preserveFilter: preserveProductAttributeFilter,
  removeFilter: removeProductAttributeFilter,
};

function removeProductAttributeFilter (){
  return {
    type: REMOVE_PRODUCT_ATTRIBUTE_FILTER,
  };
}

function preserveProductAttributeFilter (data){
  return {
    type: PRESERVE_PRODUCT_ATTRIBUTE_FILTER,
    data,
  };
}
//////////////////////
/// Customer Group ///
//////////////////////
export const CustomerGroupAction = {
  updateItems: customerGroupWillUpdateItem,
  addItem: customerGroupWillAddItems,
  removeItem: customerGroupWillRemoveItem,
};
function customerGroupWillAddItems (value){
  return {
    type: CUSTOMER_GROUP_ADD_ITEMS,
    value,
  };
}
function customerGroupWillRemoveItem (id){
  return {
    type: CUSTOMER_GROUP_REMOVE,
    id,
  };
}
function customerGroupWillUpdateItem (value){
  return {
    type: UPDATE_CUSTOMER_GROUP_ITEM,
    value,
  };
}

export const PreserveSaleOrderFilterAction = {
  preserveSaleOrderFilter: preserveSaleFilter,
  removeSaleOrderFilter: removeSaleFilter,
};

function removeSaleFilter (){
  return {
    type: REMOVE_SALE_ORDER_FILTER,
  };
}

function preserveSaleFilter (data){
  return {
    type: PRESERVE_SALE_ORDER_FILTER,
    data,
  };
}


export const PreserveSaleInvoiceFilterAction = {
  preserveSaleFilter: preserveSaleInvoiceFilter,
  removeSaleFilter: removeSaleInvoiceFilter,
};

function removeSaleInvoiceFilter (){
  return {
    type: REMOVE_SALE_INVOICE_FILTER,
  };
}

function preserveSaleInvoiceFilter (data){
  return {
    type: PRESERVE_SALE_INVOICE_FILTER,
    data,
  };
}


export const PreserveSaleShipmentFilterAction = {
  preserveSaleFilter: preserveSaleShipmentFilter,
  removeSaleFilter: removeSaleShipmentFilter,
};

function removeSaleShipmentFilter (){
  return {
    type: REMOVE_SALE_SHIPMENT_FILTER,
  };
}

function preserveSaleShipmentFilter (data){
  return {
    type: PRESERVE_SALE_SHIPMENT_FILTER,
    data,
  };
}


export const PreserveSaleMemoFilterAction = {
  preserveSaleFilter: preserveSaleMemoFilter,
  removeSaleFilter: removeSaleMemoFilter,
};

function removeSaleMemoFilter (){
  return {
    type: REMOVE_SALE_MEMO_FILTER,
  };
}

function preserveSaleMemoFilter (data){
  return {
    type: PRESERVE_SALE_MEMO_FILTER,
    data,
  };
}

export const PreserveCustomerGroup = {
  preserveFilter: preserveCustomerGroupFilter,
  removeFilter: removeCustomerGroupFilter,
};

function removeCustomerGroupFilter (){
  return {
    type: REMOVE_CUSTOMER_GROUP_FILTER,
  };
}

function preserveCustomerGroupFilter (data){
  return {
    type: PRESERVE_CUSTOMER_GROUP_FILTER,
    data,
  };
}

export const PreserveBrandFilterAction = {
  preserveFilter: preserveBrandFilter,
  removeFilter: removeBrandFilter,
};

function removeBrandFilter (){
  return {
    type: REMOVE_BRAND_FILTER,
  };
}

function preserveBrandFilter (data){
  return {
    type: PRESERVE_BRAND_FILTER,
    data,
  };
}

export const PreserveCustomerFilterAction = {
  preserveFilter: preserveCustomerFilter,
  removeFilter: removeCustomerFilter,
};

function removeCustomerFilter (){
  return {
    type: REMOVE_CUSTOMER_FILTER,
  };
}

function preserveCustomerFilter (data){
  return {
    type: PRESERVE_CUSTOMER_FILTER,
    data,
  };
}

export const PreserveBannerFilterAction = {
  preserveFilter: preserveBannerFilter,
  removeFilter: removeBannerFilter,
};

function removeBannerFilter (){
  return {
    type: REMOVE_BANNER_FILTER,
  };
}

function preserveBannerFilter (data){
  return {
    type: PRESERVE_BANNER_FILTER,
    data,
  };
}

export const PreserveTicketFilterAction = {
  preserveFilter: preserveTicketFilter,
  removeFilter: removeTicketFilter,
};

function removeTicketFilter (){
  return {
    type: REMOVE_TICKET_FILTER,
  };
}

function preserveTicketFilter (data){
  return {
    type: PRESERVE_TICKET_FILTER,
    data,
  };
}

export const PreserveFilterAction = {
  preserveFilter: preserveDataFilter,
  removeFilter: removeDataFilter,
};

function removeDataFilter (){
  return {
    type: REMOVE_FILTER,
  };
}

function preserveDataFilter (data){
  return {
    type: PRESERVE_FILTER,
    data,
  };
}

//////////////////////////
/// Warehouse Reports  ///
//////////////////////////

export const WarehouseAction = {
  printWarehouseReports,
  clearWarehouseReports,
  updateAgingType,
  updateInventoryState,
  clearInventoryState,
  changeToggleTab,
};

function printWarehouseReports (data){
  return {
    type: PRINT_WAREHOUSE_REPORTS,
    ...data,
  };
}

function clearWarehouseReports (){
  return {
    type: CLEAR_WAREHOUSE_REPORTS,
  };
}

function updateAgingType (data){
  return {
    type: UPDATE_AGING_TYPE,
    ...data,
  };
}

function updateInventoryState (data){
  return {
    type: UPDATE_INVENTORY_STATE,
    ...data,
  };
}

function clearInventoryState (){
  return {
    type: CLEAR_INVENTORY_STATE,
  };
}

function changeToggleTab (data){
  return {
    type: CHANGE_TOGGLE_TAB,
    ...data,
  };
}

//////////////////////////////////////////
/// Shipping and Tracking Information  ///
//////////////////////////////////////////
export const ShippingTrackingInfo = {
  preserveData: preserveShippingTrackingInfo,
  removeData: removeShippingTrackingInfo,
};

function removeShippingTrackingInfo (){
  return {
    type: REMOVE_SHIPPING_TRACKING_INFO,
  };
}

function preserveShippingTrackingInfo (data){
  return {
    type: PRESERVE_SHIPPING_TRACKING_INFO,
    data,
  };
}
