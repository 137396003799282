export default {
    staging: {
        route: 'https://api-staging.luxein.com/api/v1',
        socket: 'https://api-staging.luxein.com',
        firebaseKey: "staging_maintenance",
        pawnhero_url: 'https://api-staging.luxein.com/express/delivery',
        pawnhero_access_key_id: "FF6VdLPXAFYDmB9C",
        pawnhero_secret_access_key_id: "TPusNBaRyRuYI5OAR0uZMr4x0yjY2mgX"
    },
    test: {
        route: 'http://54.151.164.22:5000/api/v1',
        socket: 'http://54.151.164.22:5000',
        firebaseKey: "test_maintenance",
        pawnhero_url: 'https://api-staging.luxein.com/express/delivery',
        pawnhero_access_key_id: "FF6VdLPXAFYDmB9C",
        pawnhero_secret_access_key_id: "TPusNBaRyRuYI5OAR0uZMr4x0yjY2mgX"
    },
    development: {
        route: 'http://127.0.0.1:5000/api/v1',
        socket: 'http://127.0.0.1/',
        firebaseKey: "test_maintenance",
        pawnhero_url: 'http://127.0.0.1:7070/express/delivery',
        pawnhero_access_key_id: "FF6VdLPXAFYDmB9C",
        pawnhero_secret_access_key_id: "TPusNBaRyRuYI5OAR0uZMr4x0yjY2mgX"
    },
    production: {
        route: 'https://api-galadriel.luxein.com/api/v1',
        socket: 'https://api-galadriel.luxein.com',
        firebaseKey: "prod_maintenance",
        pawnhero_url: 'http://api-­courier-­pawnhero.codedisruptors.com:7070/express/delivery',
        pawnhero_access_key_id: "nB9FxpJxan7uymZZ",
        pawnhero_secret_access_key_id: "snDPAvgiqWshIsSiAiQUPBx2arkUKA7v"
    }
}
