import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import App from 'components/App';
import { unregister } from 'registerServiceWorker';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-table/react-table.css'
import 'index.css';

import { Store } from './Store';
import { Firebase } from './utils/firebase'

Firebase.init();

const store = Store.state
const persistor = Store.persistor(store)
persistor.purge();

unregister()
ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>
, document.getElementById('root'));

export const Persistor = {
    state: persistor
}
