import * as firebase from 'firebase';
import 'firebase/database';

import config from './config';

const env = process.env.REACT_APP_ENV;
const firebaseConfig = {
  apiKey: 'AIzaSyAZS7-DiNjxLyskpdY5woXVSttMfhn9co4',
  authDomain: 'luxein.firebaseapp.com',
  databaseURL: 'https://luxein.firebaseio.com/',
  projectId: 'luxein',
  storageBucket: 'luxein.appspot.com',
  messagingSenderId: '135871340272',
  appId: '1:135871340272:web:7df23982fe9a7e5ac52c35',
  measurementId: 'G-2CJ7G9225W',
};
function initialize() {
  // Your web app's Firebase configuration

  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
}

function checkMaintenance(callback) {
  const firebaseKey = config[env].firebaseKey;
  // Get a reference to the root of the Database
  const maintenanceRef = firebase.database().ref(`admin/web/${firebaseKey}`);
  maintenanceRef.once(
    'value',
    (snapshot) => {
      console.log('Snapshot:', snapshot);
      const flag = snapshot.val() || false;
      callback(flag);
    },
    (error) => {
      console.error('Error fetching data:', error);
    }
  );
}

// const firestore = firebase.firestore();

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const database = firebase.database();
const db = firebase.firestore();
export { db };

export { database };

export const Firebase = {
  init: initialize,
  check: checkMaintenance,
  key: config[env].firebaseKey,
  database: database,
};
