import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PropagateLoader from 'react-spinners/dist/spinners/PropagateLoader';
import logo from 'assets/LUXEIN.svg'

class Loader extends Component {
    render() {
        const { isLoading, message = 'Please wait'} = this.props
        return (
            <div className="loader"
                style={
                    {
                        display: isLoading ? "inline-grid" : "none"
                    }
                }>
                <div>
                    <img
                        className='logo' 
                        src={logo}
                        style={{
                            width: "100px"
                        }}
                        alt="LuxeIn"
                    />
                    <div className=' mt-3'>
                    <small className='text-white'>{message}</small>
                    </div>
                    <div className='mt-2 d-flex justify-content-center'>
                    <PropagateLoader
                        size={3}
                        color={"#FFFFFF"}
                        loading={isLoading}
                    />
                    </div>
                </div>
            </div>
        );
    }
}

Loader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    message: PropTypes.string,
    header: PropTypes.string,
};

export default Loader;