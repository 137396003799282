import * as Cookie from 'utils/cookie'
import * as API from 'utils/API'
import * as Help from 'utils/helpers'

const ACC_ADMIN = 'ACC_ADMIN'
const ACC_USER = 'ACC_USER'

const menus = {
    dashboard: "/dashboard",
    sales: "/sales",
    catalog: "/catalog",
    customer: "/customer",
    content: "/content",
    ticket: "/ticket",
    notifications: "/notifications",
    warehouse: "/warehouse",
    setting: "/setting",
}

const admin_menus_sublink = {
    dashboard: [],
    sales: [
        {
            type: "link",
            title: "Orders",
            url: "/sales/orders"
        },
        {
            type: "link",
            title: "Invoices",
            url: "/sales/invoices"
        },
        {
            type: "link",
            title: "Shipments",
            url: "/sales/shipment"
        },
        {
            type: "link",
            title: "Credit Memos",
            url: "/sales/credit-memo"
        },
        {
            type: "link",
            title: "Backorder",
            url: "/sales/backorder"
        },
        {
            type: "group-link",
            "group-title": "Change Tracker",
            link: [
                {
                    type: "link",
                    title: "Audit Trail",
                    url: "/sales/audit-trail"
                },
            ]
        },
    ],
    catalog: [
        {
            type: "link",
            title: "Product",
            url: "/catalog/product"
        },
        {
            type: "link",
            title: "Category",
            url: "/catalog/category"
        },
        {
            type: "link",
            title: "Brand",
            url: "/catalog/brand"
        },
        {
            type: "group-link",
            "group-title": "Promo",
            link: [
                {
                    type: "link",
                    title: "Promotions",
                    url: "/catalog/promo"
                },
            ]
        },
        {
            type: "group-link",
            "group-title": "Attribute Management",
            link: [
                {
                    title: "Attribute",
                    url: "/catalog/attributes/product",
                },
                {
                    title: "Attribute Group",
                    url: "/catalog/attribute-group",
                }
            ]
        },
        {
            type: "group-link",
            "group-title": "Change Tracker",
            link: [
                {
                    type: "link",
                    title: "Audit Trail",
                    url: "/catalog/audit-trail"
                },
            ]
        },
    ],
    customer: [
        {
            type: "link",
            title: "Registered Customers",
            url: "/customer/all-customer"
        },
        {
            type: "link",
            title: "Guest Customers",
            url: "/customer/guest-customer"
        },
        {
            type: "link",
            title: "Customer Group",
            url: "/customer/customer-group"
        },
        {
            type: "group-link",
            "group-title": "Change Tracker",
            link: [
                {
                    type: "link",
                    title: "Audit Trail",
                    url: "/customer/audit-trail"
                },
            ]
        },
    ],
    content: [
        {
            type: "link",
            title: "Block",
            url: "/content/block"
        },
        {
            type: "link",
            title: "Page",
            url: "/content/page"
        },
        {
            type: "group-link",
            "group-title": "Design",
            link: [
                {
                    title: "Banners",
                    url: "/content/banner",
                },
            ]
        },
        {
            type: "group-link",
            "group-title": "Promos",
            link: [
                {
                    title: "Promos",
                    url: "/content/promos",
                },
                {
                    title: "Setting",
                    url: "/content/promos_setting",
                },
            ]
        },
        {
            type: "group-link",
            "group-title": "Change Tracker",
            link: [
                {
                    type: "link",
                    title: "Audit Trail",
                    url: "/content/audit-trail"
                },
            ]
        },
    ],
    ticket: [
        {
            type: "link",
            title: "Tickets",
            url: "/ticket/tickets"
        },
        {
            type: "group-link",
            "group-title": "Change Tracker",
            link: [
                {
                    type: "link",
                    title: "Audit Trail",
                    url: "/ticket/audit-trail"
                },
            ]
        },
    ],
    notifications: [
        {
            type: "group-link",
            "group-title": "Promotional Notification",
            link: [
                {
                    type: "link",
                    title: "Promotional SNS",
                    url: "/notifications/sns"
                },
                {
                    type: "link",
                    title: "Promotional SMS",
                    url: "/notifications/promo-sms"
                },
                {
                    type: "link",
                    title: "Promotional Email",
                    url: "/notifications/promotional_email"
                }
            ]
        },
        {
            type: "group-link",
            "group-title": "Automated Notification",
            link: [
                // {
                //     type: "link",
                //     title: "Automatic SNS",
                //     url: "/notifications/automated/sns"
                // },
                {
                    type: "link",
                    title: "Automatic SMS",
                    url: "/notifications/automated/sms"
                },
                {
                    type: "link",
                    title: "Automatic Email",
                    url: "/notifications/automated/email"
                },
            ]
        },
        {
            type: "group-link",
            "group-title": "Change Tracker",
            link: [
                {
                    type: "link",
                    title: "Audit Trail",
                    url: "/notifications/audit-trail"
                },
            ]
        },
    ],
    warehouse: [
        {
            type: "link",
            title: "Location Manager",
            url: "/warehouse/locations"
        },
        {
            type: "link",
            title: "Reports Generation",
            url: "/warehouse/reports"
        },
        {
            type: "link",
            title: "Stock Receiving",
            url: "/warehouse/receiving"
        },
        {
            type: "link",
            title: "Stock Withdrawal",
            url: "/warehouse/withdrawal"
        },
        {
            type: "link",
            title: "Stock Return",
            url: "/warehouse/return"
        },
        {
            type: "link",
            title: "Stock Movement",
            url: "/warehouse/movement"
        },
        {
            type: "group-link",
            "group-title": "Change Tracker",
            link: [
                {
                    type: "link",
                    title: "Audit Trail",
                    url: "/warehouse/audit-trail"
                },
            ]
        },
    ],
    setting: [
        {
            type: "link",
            title: "All Users",
            url: "/setting/permission/user"
        },
        {
            type: "link",
            title: "User Roles",
            url: "/setting/permission/roles"
        },
        {
            type: "link",
            title: "Shipping Fees",
            url: "/setting/shipping-fees"
        },
        {
            type: "link",
            title: "SEO Management",
            url: "/setting/permission/seo"

        },
        // {
        //     type: "link",
        //     title: "Sales Dashboard",
        //     url: "/setting/permission/sales-dasboard"
        // },
        {
            type: "link",
            title: "Maintenance",
            url: "/setting/maintenance"
        },
        {
            type: "link",
            title: "URL Rewrite",
            url: "/setting/url-rewrite"
        },

        {
            type: 'link',
            title: 'Waitlist',
            url: '/setting/waitlist',
        },
        {
            type: "group-link",
            "group-title": "Change Tracker",
            link: [
                {
                    type: "link",
                    title: "Audit Trail",
                    url: "/setting/audit-trail"
                },
            ]
        },
    ],
}

export function getPermissionSubLink(permission) {
    const userInfo = Cookie.getUser()
    let lvl2Menu, submodule
    // console.log(permission)
    if (userInfo) {
        const resources = userInfo.data.resources
        const subMenu = permission === 'dashboard' ? permission.charAt(0).toUpperCase() + permission.slice(1) : permission.charAt(0).toUpperCase() + permission.slice(1) + '_'

        //Get all the level 2 menus in the list of resources
        lvl2Menu = permission === 'dashboard' ? resources.filter(item => item === 'Dashboard') : resources.filter(item => item.includes(subMenu))
        submodule = admin_menus_sublink[permission]

        //Hide level 2 sub modules that was not selected in the user roles resources
        submodule = submodule.map(item => {
            if (item.type === 'link') {// Handle the Link type
                const access = permission.charAt(0).toUpperCase() + permission.slice(1) + '_' + item.title.replace(/\s/g, '') + '_View'
                // console.log("ACCESS " + access)
                // console.log("lvl2Menu " + lvl2Menu)
                //Check if the user has a view access in level 2 module
                if (lvl2Menu.includes(access)) {
                    return { ...item }
                }
            } else {//Handle the Group link type

                const acc_filter = item.link.map(sub => {
                    const access_define = permission.charAt(0).toUpperCase() + permission.slice(1) + '_' + sub.title.replace(/\s/g, '') + '_View'

                    if (lvl2Menu.includes(access_define)) {
                        return { ...sub }
                    }
                    return undefined
                })
                const access = {
                    ...item,
                    link: acc_filter.filter(d => d !== undefined)
                }
                return access.link.length !== 0 ? access : undefined
            }
            return undefined
        })

        // console.log(`SUBMODULE \n${JSON.stringify(submodule.filter(item => item !== undefined))}`)
        return submodule = submodule.filter(item => item !== undefined)
    } else {
        // console.log(admin_menus_sublink[permission])
        return admin_menus_sublink[permission]
    }
}

let isUserNotified = false
export function getPermission(type, userAccess = null, toLogout = false) {
    let userInfo
    let isResourceUpdated = false
    userInfo = Cookie.getUser()

    let lvl1Menu, sidebarMenu
    if (userInfo) {
        if (!toLogout) {
            API.getUserRessources(userInfo.data.user_id)
                .then(res => {
                    const resources = userInfo.data.resources
                    res.data.forEach(element => {
                        if (!resources.includes(element)) {
                            isResourceUpdated = true
                        }
                    });

                    if (isResourceUpdated === false) {
                        if (resources.length !== res.data.length) {
                            isResourceUpdated = true
                        }
                    }

                    if (isResourceUpdated) {
                        if (!isUserNotified) {
                            Help.toastPop({ message: 'You have an update on resource access!', type: 'success', autoClose: 5000 })
                            isUserNotified = true
                        }
                        const data = {
                            data: {
                                ...userInfo.data,
                                resources: res.data,
                            }
                        }
                        Cookie.saveUser(data, (isSave = false) => { })
                    }
                })
                .catch(err => {
                })

            userInfo = Cookie.getUser()
        }

        isUserNotified = false
        const resources = userInfo.data.resources
        lvl1Menu = resources.filter(item => item.includes('_') === false)
        lvl1Menu = lvl1Menu.map(item => item.toLowerCase())
        sidebarMenu = Object.keys(menus).map(key => {
            if (lvl1Menu.includes(key)) {
                return {
                    title: key,
                    url: menus[key]
                }
            }
            return undefined
        })
        return sidebarMenu = sidebarMenu.filter(item => item !== undefined)
    } else {
        return Object.keys(menus).map(key => ({
            title: key,
            url: menus[key]
        }))
    }
}

export function formatRole(type) {
    switch (type) {
        case 'USER':
            return ACC_USER
        default:
            return ACC_ADMIN
    }
}
