import { persistStore, persistReducer } from 'redux-persist'
import { createStore, applyMiddleware, compose } from 'redux'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import storage from 'redux-persist/lib/storage'
import reducer from '../reducers'

const env = process.env.REACT_APP_ENV
const logger = store => next => action => {
    // console.group(action.type)
    // console.info('dispatching', action)
    let result = next(action)
    // console.log('next state', store.getState())
    // console.groupEnd(action.type)
    return result
}

let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
if(env === "production") {
    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function () {}
     }
    composeEnhancers = compose
}

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ['menu'],
    stateReconciler: hardSet
};

const pReducer = persistReducer(persistConfig, reducer);

function getStore() {
    return createStore(
        pReducer,
        composeEnhancers(
          applyMiddleware(logger)
        )
    )
}

function getPersistor(store) {
    return persistStore(store)
}

export default {
    state: getStore(),
    persistor: getPersistor
}