import React, { Component } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import Idle from 'react-idle';
import asyncComponent from 'components/AsyncComponent';
import * as API from 'utils/API';

import { Firebase } from 'utils/firebase';
import * as firebase from 'firebase';
import 'firebase/database';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import * as Cookie from 'utils/cookie';
import { getPermission } from 'utils/permissions';

const AsyncResetForm = asyncComponent(() => import('components/LoginReset'));
const AsyncLogin = asyncComponent(() => import('components/Login'));
const AsyncMain = asyncComponent(() => import('components/Main'));
const AsyncMaintenance = asyncComponent(() => import('components/Maintenance'));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      isCurrent: false,
      hasPrevRoute: false,
      under_maintenance: false,
    };

    this.handleMaintenance = this.handleMaintenance.bind(this);
    this.handleMaintenance();
  }

  componentWillReceiveProps(nextProps) {
    var routeChanged = nextProps.location !== this.props.location;
    this.setState({ hasPrevRoute: routeChanged });
  }
  componentDidMount() {
    // document.title = "Market Hero"
    const { hasPrevRoute } = this.state;
    const {
      location: { pathname },
      history,
    } = this.props;
    const role = Cookie.getRole();
    const menus = getPermission(role);
    const urls = menus.map((item) => item.url);
    const defaultUrls = [
      ...urls,
      '/', // default route
      '/login', // default login route
      '/cart',
    ]; //
    if (Cookie.getToken()) {
      this.setState(() => ({ hasToken: true }));
      if (!defaultUrls.includes(`/${pathname.split('/')[1]}`)) {
        return hasPrevRoute ? history.goBack() : history.push(defaultUrls[0]);
      }
      if (pathname === '/' || pathname === '/login') {
        history.push(defaultUrls[0]);
      }
    }
  }
  async handleMaintenance() {
    const ref = await firebase.database().ref(`admin/web/${Firebase.key}`);
    await ref.on('value', (snapshot) => {
      const hasAccess = Cookie.hasAccess();
      const onMaintenance = snapshot.val() || false;
      if (onMaintenance) {
        // logs out the user
        if (hasAccess) {
          this.handleLogOut();
        }
      }
      this.setState({
        under_maintenance: onMaintenance,
      });
    });
  }
  handleLogOut = () => {
    const isNavigate =
      this.props.history.location.pathname.split('/')[1] !== 'warehouse';
    isNavigate &&
      API.logout().then((_) => {
        Cookie.removeUser();
      });
    return (
      <div>
        <ToastContainer autoClose={3000} />
        <Route path="/" component={AsyncMain} />
        <Route exact path="/reset/admin" component={AsyncResetForm} />
        <Route exact path="/login" component={AsyncLogin} />
      </div>
    );
  };
  render() {
    const { under_maintenance } = this.state;
    return (
      <Idle
        timeout={1200000}
        render={({ idle }) => (
          <>
            {under_maintenance ? (
              <AsyncMaintenance />
            ) : idle ? (
              this.handleLogOut()
            ) : (
              <div>
                <ToastContainer autoClose={3000} />
                <Route path="/" component={AsyncMain} />
                <Route exact path="/reset/admin" component={AsyncResetForm} />
                <Route exact path="/login" component={AsyncLogin} />
              </div>
            )}
          </>
        )}
      />
    );
  }
}

export default withRouter(App);
