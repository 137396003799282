import { combineReducers } from "redux";

import {
  INSERT_DATA_PRODUCT,
  REMOVE_DATA_PRODUCT,
  MENU_APPLY_ITEMS,
  PURGE_ALL,
  CUSTOMER_GROUP_ADD_ITEMS,
  CUSTOMER_GROUP_REMOVE,
  UPDATE_CUSTOMER_GROUP_ITEM,
  PRESERVE_PRODUCTFILTER,
  REMOVE_PRODUCTFILTER,
  PRESERVE_SALE_ORDER_FILTER,
  REMOVE_SALE_ORDER_FILTER,
  PRESERVE_SALE_INVOICE_FILTER,
  REMOVE_SALE_INVOICE_FILTER,
  PRESERVE_SALE_SHIPMENT_FILTER,
  REMOVE_SALE_SHIPMENT_FILTER,
  PRESERVE_SALE_MEMO_FILTER,
  REMOVE_SALE_MEMO_FILTER,
  PRESERVE_CUSTOMER_GROUP_FILTER,
  REMOVE_CUSTOMER_GROUP_FILTER,
  PRESERVE_BRAND_FILTER,
  REMOVE_BRAND_FILTER,
  PRESERVE_BANNER_FILTER,
  REMOVE_BANNER_FILTER,
  PRESERVE_CUSTOMER_FILTER,
  REMOVE_CUSTOMER_FILTER,
  PRESERVE_PRODUCT_ATTRIBUTE_FILTER,
  REMOVE_PRODUCT_ATTRIBUTE_FILTER,
  PRESERVE_TICKET_FILTER,
  REMOVE_TICKET_FILTER,
  PRESERVE_FILTER,
  REMOVE_FILTER,
  USER_ACCESS,
  PURGE_USER_ACCESS,
  PRESERVE_SHIPPING_TRACKING_INFO,
  REMOVE_SHIPPING_TRACKING_INFO,
  PRINT_WAREHOUSE_REPORTS,
} from "../actions";

function listShippingTrackingInfo (state = { data: {} }, action){
  switch (action.type) {
    case PRESERVE_SHIPPING_TRACKING_INFO: {
      const { data } = action;

      return {
        ...state,
        data,
      };
    }
    case REMOVE_SHIPPING_TRACKING_INFO: {
      return {
        data: {},
      };
    }
    default: {
      return state;
    }
  }
}

const initialUserAccess = {
  currentData: null,
};

function userAccessData (state = initialUserAccess, action){
  switch (action.type) {
    case USER_ACCESS:
      const { data } = action;
      return {
        ...state,
        currentData: data,
      };
    case PURGE_USER_ACCESS:
      return {
        ...state,
        currentData: null,
      };
    default:
      return state;
  }
}

const initialFilter = {
  data: {},
};

function listProduct (state = initialFilter, action){
  switch (action.type) {
    case PRESERVE_PRODUCTFILTER: {
      const { data } = action;

      return {
        ...state,
        data,
      };
    }
    case REMOVE_PRODUCTFILTER: {
      return {
        data: {},
      };
    }
    default: {
      return state;
    }
  }
}

const initialReserveOrderFilter = {
  data: {},
};

function listSaleOrder (state = initialReserveOrderFilter, action){
  switch (action.type) {
    case PRESERVE_SALE_ORDER_FILTER: {
      const { data } = action;

      return {
        ...state,
        data,
      };
    }
    case REMOVE_SALE_ORDER_FILTER: {
      return {
        data: {},
      };
    }
    default: {
      return state;
    }
  }
}

const initialReserveInvoiceFilter = {
  data: {},
};

function listSaleInvoice (state = initialReserveInvoiceFilter, action){
  switch (action.type) {
    case PRESERVE_SALE_INVOICE_FILTER: {
      const { data } = action;

      return {
        ...state,
        data,
      };
    }
    case REMOVE_SALE_INVOICE_FILTER: {
      return {
        data: {},
      };
    }
    default: {
      return state;
    }
  }
}

const initialReserveShipmentFilter = {
  data: {},
};

function listSaleShipment (state = initialReserveShipmentFilter, action){
  switch (action.type) {
    case PRESERVE_SALE_SHIPMENT_FILTER: {
      const { data } = action;

      return {
        ...state,
        data,
      };
    }
    case REMOVE_SALE_SHIPMENT_FILTER: {
      return {
        data: {},
      };
    }
    default: {
      return state;
    }
  }
}

const initialReserveMemoFilter = {
  data: {},
};

function listSaleMemo (state = initialReserveMemoFilter, action){
  switch (action.type) {
    case PRESERVE_SALE_MEMO_FILTER: {
      const { data } = action;

      return {
        ...state,
        data,
      };
    }
    case REMOVE_SALE_MEMO_FILTER: {
      return {
        data: {},
      };
    }
    default: {
      return state;
    }
  }
}

const initialReserveCustomerGroupFilter = {
  data: {},
};
function listCustomerGroup (state = initialReserveCustomerGroupFilter, action){
  switch (action.type) {
    case PRESERVE_CUSTOMER_GROUP_FILTER: {
      const { data } = action;

      return {
        ...state,
        data,
      };
    }
    case REMOVE_CUSTOMER_GROUP_FILTER: {
      return {
        data: {},
      };
    }
    default: {
      return state;
    }
  }
}

const initialReserveBrandFilter = {
  data: {},
};

function listBrand (state = initialReserveBrandFilter, action){
  switch (action.type) {
    case PRESERVE_BRAND_FILTER: {
      const { data } = action;

      return {
        ...state,
        data,
      };
    }
    case REMOVE_BRAND_FILTER: {
      return {
        data: {},
      };
    }
    default: {
      return state;
    }
  }
}

const initialReserveBannerFilter = {
  data: {},
};

function listBanner (state = initialReserveBannerFilter, action){
  switch (action.type) {
    case PRESERVE_BANNER_FILTER: {
      const { data } = action;

      return {
        ...state,
        data,
      };
    }
    case REMOVE_BANNER_FILTER: {
      return {
        data: {},
      };
    }
    default: {
      return state;
    }
  }
}

const initialReserveTicketFilter = {
  data: {},
};

function listTicket (state = initialReserveTicketFilter, action){
  switch (action.type) {
    case PRESERVE_TICKET_FILTER: {
      const { data } = action;

      return {
        ...state,
        data,
      };
    }
    case REMOVE_TICKET_FILTER: {
      return {
        data: {},
      };
    }
    default: {
      return state;
    }
  }
}
const initialReserveCustomerFilter = {
  data: {},
};

function listCustomer (state = initialReserveCustomerFilter, action){
  switch (action.type) {
    case PRESERVE_CUSTOMER_FILTER: {
      const { data } = action;

      return {
        ...state,
        data,
      };
    }
    case REMOVE_CUSTOMER_FILTER: {
      return {
        data: {},
      };
    }
    default: {
      return state;
    }
  }
}

const initialReserveFilter = {
  data: {},
};
function preserveData (state = initialReserveFilter, action){
  switch (action.type) {
    case PRESERVE_FILTER: {
      const { data } = action;

      return {
        ...state,
        data,
      };
    }
    case REMOVE_FILTER: {
      return {
        data: {},
      };
    }
    default: {
      return state;
    }
  }
}

const initialFormProductState = {};

function formProduct (state = initialFormProductState, action){
  switch (action.type) {
    case INSERT_DATA_PRODUCT:
      const { value } = action;

      return {
        ...state,
        ...value,
      };
    case REMOVE_DATA_PRODUCT:
      return {};

    default:
      return state;
  }
}

const initialMenuState = {
  items: [],
  userType: null,
};

function menu (state = initialMenuState, action){
  switch (action.type) {
    case MENU_APPLY_ITEMS:
      const { menuItems, userType } = action;

      return {
        ...state,
        items: menuItems,
        type: userType,
      };
    case PURGE_ALL:
      return {
        ...initialMenuState,
      };
    default:
      return state;
  }
}

const initialReserveProductAttributeFilter = {
  data: {},
};

function listProductAttribute (state = initialReserveProductAttributeFilter, action){
  switch (action.type) {
    case PRESERVE_PRODUCT_ATTRIBUTE_FILTER: {
      const { data } = action;

      return {
        ...state,
        data,
      };
    }
    case REMOVE_PRODUCT_ATTRIBUTE_FILTER: {
      return {
        data: {},
      };
    }
    default: {
      return state;
    }
  }
}

///////////////////////////////////////////
//////////// Customer Group ///////////////
///////////////////////////////////////////
const initialCustomerGroupState = {
  items: [],
};
function customer_group (state = initialCustomerGroupState, action){
  const { value, id } = action;
  switch (action.type) {
    case UPDATE_CUSTOMER_GROUP_ITEM:
      return {
        items: value,
      };

    case CUSTOMER_GROUP_ADD_ITEMS:
      return {
        ...state,
        items: [ ...state.items, value ],
      };
    case CUSTOMER_GROUP_REMOVE: {
      return {
        ...state,
        items: state.items.filter((d) => d.customer_group_id !== parseInt(id)),
      };
    }
    default:
      return state;
  }
}

///////////////////////////////////////////
//////////// For Pricing //////////////////
///////////////////////////////////////////
// const initialForPricingState = {
//   items:[
//     {'sku':'123','category':'Smartphone', 'item_name':'Nokia 7', 'principal':'Iphone', 'case_weight':'150g', 'case_volume':'500ml', 'location':'GSTG-A01', 'requested':true},
//     {'sku':'234','category':'Smartphone', 'item_name':'Nokia 6', 'principal':'Iphone', 'case_weight':'150g', 'case_volume':'500ml', 'location':'GSTG-A01', 'requested':true},
//     {'sku':'678','category':'Camera', 'item_name':'Canon 4000', 'principal':'Iphone', 'case_weight':'150g', 'case_volume':'500ml', 'location':'GSTG-A02',},
//     {'sku':'789','category':'Camera', 'item_name':'Canon 505', 'principal':'Iphone', 'case_weight':'150g', 'case_volume':'500ml', 'location':'GSTG-A02',},
//   ],
//   pickedItem:{}
// }
// function for_pricing (state = initialForPricingState, action) {
//   switch (action.type) {
//       default :
//         return state
//   }
// }
const initialAdminOrderState = {
  allCustomerInfo: {},
};

const admin_order = (state = initialAdminOrderState, action) => {
  switch (action.type) {
    case "PERSIST_USER_INPUT":
      return {
        ...state,
      };
    default:
      return state;
  }
};

const initialWarehouseReportState = {
  inventory: false,
  aging: false,
  agingType: "price",
  inventoryState: {
    page: 0,
    pageSize: 10,
    sort_by: "entity_id",
    sort: "ASC",
    category: "",
    brand: "",
    sku: "",
    location: "",
    order_status: "",
    item_status: "",
    loan_amount: [],
    price: [],
    first_auction_date: [],
    age: "",
    aging: "",
  },
  activeTab: 1,
  inventorySize: 0,
  agingSize: 0,
};

const warehouse_report = (state = initialWarehouseReportState, action) => {
  switch (action.type) {
    case "PRINT_WAREHOUSE_REPORTS":
      return {
        ...state,
        ...action,
      };

    case "CLEAR_WAREHOUSE_REPORTS":
      return {
        ...initialWarehouseReportState,
      };

    case "UPDATE_AGING_TYPE": {
      return {
        ...state,
        ...action,
      };
    }

    case "UPDATE_INVENTORY_STATE": {
      return {
        ...state,
        inventoryState: {
          ...state.inventoryState,
          ...action,
        },
      };
    }

    case "CLEAR_INVENTORY_STATE": {
      return {
        ...state,
        inventoryState: {
          ...state.inventoryState,
          page: 0,
          pageSize: 10,
        },
      };
    }

    case "CHANGE_TOGGLE_TAB": {
      return {
        ...state,
        ...action,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  listProduct,
  formProduct,
  menu,
  customer_group,

  listSaleOrder,
  listSaleInvoice,
  listSaleShipment,
  listSaleMemo,
  listBrand,
  listCustomer,
  listCustomerGroup,
  listBanner,
  listTicket,
  listProductAttribute,
  preserveData,
  userAccessData,

  admin_order,
  warehouse_report,
  listShippingTrackingInfo,
});
